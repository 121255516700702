import { template as template_f4457b38c3cc4a7abc8b321ab3931f39 } from "@ember/template-compiler";
const WelcomeHeader = template_f4457b38c3cc4a7abc8b321ab3931f39(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
