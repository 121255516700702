import { template as template_19b1491acf894e50b93b5cc1d2dc3519 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_19b1491acf894e50b93b5cc1d2dc3519(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
